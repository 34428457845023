import React, {useRef, useState, useEffect, useCallback, useMemo} from "react"

import classNames from 'classnames/bind'
import styles from '@styles/pages/philosophy.module.scss'

import Layout from "@comp/layout"
import SEO from '@comp/seo'

import PageTitle from '@comp/page_title'
import PageCopy from '@comp/page_copy'


const cx = classNames.bind(styles)

// markup
const PhilosophyPage = ({ location,navigate,current,entry,exit,mount,transitionStatus }) => {

  const [copyVisible, setCopyVisible] = useState(false);
  const copyTimer = useRef(null)

  useEffect( () => {
    if(!copyTimer.current) {
      copyTimer.current = setTimeout(() => {
        setCopyVisible(true)
      },mount ? 500 : 800)
    }
  },[mount])


  return (
    <Layout location={location} pageName="philosophy">
      <SEO
        title={`企業理念`}
        description={`MIRAI SAKE COMPANYは、「日本酒の可能性を信じ、世界中にSAKE文化を根付かせる」ことを企業理念に事業展開しています。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label='企業理念'
            labelEn='PHILOSOPHY'
          />
        </div>

        <section className={styles.sake}>
          <h1 className={styles.sake__title}>
            <PageCopy visible={copyVisible} delay="100">未来の<br />基幹産業を創る</PageCopy></h1>
          <section className={styles.sake__section}>
            <div className={styles.sake__section__img}>
              <picture>
                <source
                  media="(max-width:993px)"
                  srcSet="
                    /images/philosophy/sp/philosophy-img-01.jpg,
                  "
                />
                <img
                  src="/images/philosophy/philosophy-img-01.jpg"
                  alt=""
                  width="1120"
                  height="301"
                />
              </picture>
            </div>
            <div className={styles.sake__section__body}>
              <h1 className={styles.sake__section__title}>ますます多くの国で<br />SAKEが<br />注目されている</h1>
              <p className={styles.sake__section__txt}>SAKEは日本で醸される日本酒（Japanese Sake）を中心としながらも、それに留まらず現在では海外15か国で生産されている「米」を原料としたグローバルな醸造酒です。<br />元々SAKEはジャポニカ米で造られていましたが、現在ではインディカ米でもSAKEを造ることができるようになり、ますます多くの国でSAKEが注目されています。<br />では将来SAKE市場はどの程度までの広がりを持ちうるのでしょうか。</p>
            </div>
          </section>
          <section className={styles.sake__section}>
            <div className={styles.sake__section__img}>
              <picture>
                <source
                  media="(max-width:993px)"
                  srcSet="
                    /images/philosophy/sp/philosophy-img-02.jpg,
                  "
                />
                <img
                  src="/images/philosophy/philosophy-img-02.jpg"
                  alt=""
                  width="1120"
                  height="301"
                />
              </picture>
            </div>
            <div className={styles.sake__section__body}>
              <h1 className={styles.sake__section__title}>SAKE市場を<br />50兆円産業に</h1>
              <p className={styles.sake__section__txt}>ある国の醸造文化は、原料である穀物・果実と密接に関連しています。古来よりブドウのあるところではワインやブランデーが、小麦のあるところではビールやウィスキーが醸されてきました。<br />また、原料の生産規模とそれらを原料とする酒類の市場規模には一定の相関があると言われています。<br />改めて米とSAKEについて考えてみます。そもそもSAKEの原料である米は、小麦に次いで世界2位の食用穀物であり、世界中で生産されるブドウの6倍以上の生産規模を誇ります。<br />小麦を原料とするビール市場は世界で約66兆円、ブドウを原料とするワイン市場規模は世界で約33兆円です。<br />これらを踏まえると、原料ポテンシャルから考えたSAKEの将来市場規模は、50兆円レベルを目指すことができると考えています。<br />つまり、SAKE産業は、守るべき伝統産業という受け身のものではないのです。それどころか、未来の日本をリードし、世界中でも雇用を産むまさに基幹産業になる可能性を秘めているアクティブで成長している産業なのです。私たちは、その可能性を開花させるため、着実に歩んでいきたいと考えています。</p>
            </div>
          </section>
        </section>

        <section className={styles.greetings}>
          <div className={styles.greetings__portrait__sp}>
            <img
              src="/images/philosophy/sp/philosophy-img-ceo.jpg"
              srcSet="
                /images/philosophy/sp/philosophy-img-ceo.jpg
              "
              alt=""
            />
          </div>
          <div className={styles.greetings__inner}>
            <h1 className={styles.greetings__title}>代表<br />ご挨拶</h1>
            <div className={styles.greetings__body}>
              <div className={styles.greetings__contents}>
                <p className={styles.greetings__txt}>古来より、穀物や果実のあるところから多様なお酒が醸され、愛されてきました。</p>
                <p className={styles.greetings__txt}>これら穀物や果実は一般に足が早いものですが、古来より続く「醸造」の力でお酒になることで、 数年・数十年単位の命を与えられ、また原料の状態とは比較にならない付加価値を産み出します。<br />つまり醸造とは穀物や果実の寿命を大きく伸ばし、また個性やオリジナリティを産み出す技術なのです。<br />そして我々が愛するSAKEは、米と水が原料となり、そこに造り手のクラフトマンシップと技術が加わって醸されます。</p>
                <div className={styles.greetings__img}>
                  <img
                    src="/images/philosophy/philosophy-img-03.jpg"
                    srcSet="
                      /images/philosophy/philosophy-img-03.jpg
                    "
                    alt=""
                  />
                </div>
                <p className={styles.greetings__txt}>私はSAKEに出会ってから、百を超える蔵を回りました。 その度に、SAKEの美味しさと個性の幅、そして蔵元の造り手のパッションに感動してきました。また同時に、SAKEが醸す人の和や空間の居心地の楽しさと居心地の良さの虜にもなってきました。</p>
                <p className={styles.greetings__txt}>では、これからのSAKEの世界はどのようになっていくのでしょうか。<br />私はSAKEの醸造において、まさに今が黄金期と言えると考えています。<br />杜氏組合を中心とした伝統の造りに、農業大学をはじめとするアカデミックな知の蓄積とテクノロジーの進化を背景とした新しいアプローチが若手の卒業生たちを中心に実践され、 両者が融合して新しい世界を創りだしているからです。</p>
                <p className={styles.greetings__txt}>また、その技術が日本を超え、世界中に広がっていく中で、 楽しみ方の再発見がなされたり、多様性が生まれたりしています。<br />例えば、SAKEが世界へ広がり、同時にその歴史が振り返られる中で、熟成酒の魅力が再発見され、ヴィンテージのSAKEが世界中で愛されつつあります。<br />また名産地の米を買ってくるのではなく、 地の米と水にこだわったまるでワインのテロワールのようなSAKEも同様により愛されるようになってきています。</p>
                <div className={styles.greetings__img}>
                  <img
                    src="/images/philosophy/philosophy-img-04.jpg"
                    srcSet="
                      /images/philosophy/philosophy-img-04.jpg
                    "
                    alt=""
                  />
                </div>
                <p className={styles.greetings__txt}>米は土壌を代表する農産物、水は天からの天然資源、そして技術は歴史が紡いだ人の営み。 そう、SAKEは大地の恵みと天の恵みと技術の歴史がその一滴に詰まったまさに“Essence of local”であり、 日本酒は”Essence of Japan”と言えるものなのです。そして現在進行形で世界中で様々なSAKE、"Essence of United States”や"Essence of France"と言えるSAKEも醸されはじめています。</p>
                <p className={styles.greetings__txt}>MIRAI SAKE COMPANYは、SAKEの未来を、農家、醸造家、そして消費者の皆さまと共創していきたいと考えています。</p>
                <p className={styles.greetings__signature}>山本 祐也</p>
              </div>
            </div>
          </div>
          <div className={styles.greetings__portrait}></div>
        </section>
      </main>
    </Layout>
  )
}

export default PhilosophyPage
